<template>
  <el-dialog v-dialog-drag :title="title" :width="width" :visible="visible" :close-on-click-modal="false"
    @close="handleClose">
    <el-form ref="editForm" :model="data" :rules="rules" :inline="true" label-width="100px">
      <el-form-item label="类型：" prop="BotType">
        <el-select v-model="data.BotType" placeholder="请选择类型" style="width: 400px">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="账号：" prop="BotUid">
        <el-input v-model="data.BotUid" :disabled="!isNew" placeholder="请输入账号" style="width: 400px" />
      </el-form-item>
      <el-form-item label="昵称：" prop="BotName">
        <el-input v-model="data.BotName" placeholder="请输入昵称" style="width: 400px" />
      </el-form-item>
      <el-form-item label="发货仓库：" prop="WID" v-if="$store.state.routes.systemSetting.MultiWarehouse">
        <warehouse-select :value.sync="data.WID" select_style="width:400px;"></warehouse-select>
        <div style="color: #9da5b1; font-size: 13px;">
          (不设置为默认仓库)
        </div>
      </el-form-item>
      <el-form-item label="所属档口：" prop="StoreID">
        <store-select :value.sync="data.StoreID" select_style="width:400px;"></store-select>
      </el-form-item>
      <!-- <el-form-item label="插件Url：" prop="BotUrl">
        <el-input v-model="data.BotUrl" placeholder="请输入插件Url" />
      </el-form-item> -->
      <el-form-item label="备注：" prop="Remark">
        <el-input v-model="data.Remark" type="textarea" style="width: 400px" :rows="2" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
      <el-button type="primary" size="small" :loading="loading" @click="handleSave">保存</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
import StoreSelect from "@/views/components/common/StoreSelect.vue";
export default {
  name: "StallEditAccount",
  components: {
    WarehouseSelect,
    StoreSelect
  },
  data() {
    return {
      loading: false,
      visible: false,
      width: "580px",
      title: "新增账号",
      isNew: true,
      options: [
        { value: 0, label: "QQ" },
        { value: 1, label: "微信" },
        { value: 2, label: "QQNT" },
      ],
      data: {},
      rules: {
        BotType: [{ required: true, message: "请选择类型", trigger: "change" }],
        BotUid: [{ required: true, message: "请输入账号", trigger: "blur" }],
        BotName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
      },
    };
  },
  methods: {
    initDialog(title, id, initData) {
      this.visible = true;

      if (title) {
        this.title = title;
      }

      if (!id) {
        this.isNew = true;
        this.data = {
          // BotUrl: 'http://localhost:5700'
        };
        if (initData) {
          this.data = initData;
        }
      } else {
        this.isNew = false;
        this.$nextTick(async () => {
          const { data } = await submit(`/api/stallBotAccount/get?id=${id}`);
          this.data = data;
        });
      }
    },
    handleSave() {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return false;

        const param = JSON.parse(JSON.stringify(this.data));

        let result;
        this.loading = true;
        if (this.isNew) {
          result = await submit("/api/stallBotAccount/create", param).finally(
            () => {
              this.loading = false;
            }
          );
        } else {
          result = await submit("/api/stallBotAccount/update", param).finally(
            () => {
              this.loading = false;
            }
          );
        }

        if (result.success) {
          this.handleClose();
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("handleSearch");
        } else {
          this.$message({
            type: "error",
            message: result.msg,
          });
        }
      });
    },
    handleClose() {
      this.visible = false;
      this.data = {};
      this.$refs.editForm.resetFields();
    },
  },
};
</script>
